/* ==============================
    Basic/generic global styles
 ============================== */

body {
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'pnum' on, 'lnum' on, 'ss09' on;
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    @apply transition-opacity duration-300 ease-out-quad;
    opacity: 1;
}

.no-js {
    img.lazyload {
        display: none;
    }
}

::selection {
    background: rgba(213, 209, 203, 0.99);
    color: #433E3A;
}

sup {
    font-size: 0.5em;
    top: -0.7em;
}
