/**
========================
Custom Tailwind components
https://tailwindcss.com/docs/extracting-components
========================
 */

@layer components {

    .h1 {
        @apply font-bold text-26 leading-28 m:text-32 m:leading-34 l:text-48 l:leading-56;
    }

    .h2 {
        @apply font-bold text-24 leading-28 m:leading-32 l:text-36 l:leading-44;
    }

    .h2-alt {
        @apply font-bold text-24 leading-28 m:text-18 m:leading-24 l:text-24 l:leading-32;
    }

    .h3 {
        @apply font-bold text-18 leading-24 l:text-28 l:leading-34;
    }

    .h3-alt {
        @apply text-18 leading-24 l:text-28 l:leading-34 tracking-002;
    }

    .h4 {
        @apply font-bold text-18 leading-22 l:text-24 l:leading-32 tracking-001;
    }

    .h5 {
        @apply font-bold text-14 leading-24 l:text-20 l:leading-34 tracking-002 l:tracking-003;
    }

    .ingress {
        @apply font-light text-18 leading-24 l:text-24 l:leading-36 tracking-002;
    }

    .ingress-mini {
        @apply font-light text-16 leading-26 l:text-18 l:leading-32 tracking-002;
    }

    .small-text {
        @apply text-12 leading-16 l:text-16 l:leading-24 tracking-002 l:tracking-003;
    }

    .small-text-mono-timer {
        @apply font-medium text-12 leading-16 l:text-16 l:leading-24 tracking-003;
    }

    .menu-heading {
        @apply uppercase text-14 leading-16 l:text-16 l:leading-24 tracking-006;
    }

    .icon-frame {
        @apply border-2 border-beige;
        width: 36px;
        height: 36px;

        @screen m {
            width: 40px;
            height: 40px;
        }
        @screen l {
            width: 48px;
            height: 48px;
        }

        &--caption {
            @apply border-2 border-beige;
            width: 24px;
            height: 24px;

            @screen l {
                width: 32px;
                height: 32px;
            }
        }
    }

    .icon {
        width: 24px;
        height: 24px;

        &--large {
            width: 32px;
            height: 32px;
        }

        &--caption {
            width: 18px;
            height: 18px;

            @screen l {
                width: 24px;
                height: 24px;
            }
        }

        &--responsive {
            width: 18px;
            height: 18px;

            @screen m {
                width: 20px;
                height: 20px;
            }
            @screen l {
                width: 24px;
                height: 24px;
            }
        }
    }

    .large-cta-icon {
        width: 48px;
        height: 48px;

        @screen m {
            width: 88px;
            height: 88px;
        }

        svg {
            width: 36px;
            height: 36px;

            @screen m {
                width: 66px;
                height: 66px;
            }
        }
    }

    .pad-top {
        padding-top: 88px;

        @screen m {
            padding-top: 90px;
        }
        @screen l {
            padding-top: 120px;
        }
    }

    .aspelin-ramm-logo {
        width: 128px;
        height: 26px;

        @screen m {
            width: 108px;
            height: 22px;
        }
        @screen l {
            width: 170px;
            height: 35px;
        }
    }

    .video-play-button {
        left: 20px;
        bottom: 20px;

        @screen m {
            left: 28px;
            bottom: 28px;
        }

        @screen l {
            left: 64px;
            bottom: 64px;
        }

        .bleed-left & {
            left: auto !important;
            right: 20px;

            @screen m {
                right: 28px;
            }

            @screen l {
                right: 64px;
            }
        }

        &__icon {
            width: 32px;
            height: 32px;

            @screen m {
                width: 40px;
                height: 40px;
            }
            @screen l {
                width: 50px;
                height: 50px;
            }

            svg {
                width: 16px;
                height: 16px;

                @screen m {
                    width: 20px;
                    height: 20px;
                }
                @screen l {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        &__text {
            height: 32px;
            line-height: 34px;

            @screen m {
                height: 40px;
                line-height: 42px;
            }
            @screen l {
                height: 50px;
                line-height: 52px;
            }
        }
    }

    .accordion-icon {
        width: 24px;
        height: 24px;
        top: 18px;
        
        @screen l {
            top: 45px;
        }

        span {
            width: 12px;
            height: 2px;
            transform-origin: center;
            left: 6px;
            top: 11px;

            @screen l {
                width: 20px;
                left: 2px;
            }
        }

        span:first-child {
            transform: rotate(-90deg);
        }
    }

    [aria-expanded="true"] .accordion-icon span:first-child {
        width: 0;
    }

    .before-image {
        transform: translateX(-16px);

        @screen sp {
            transform: translateX(-32px);
        }
        @screen m {
            transform: none;
        }

        figcaption {
            transform: translateX(16px);

            @screen sp {
                transform: translateX(32px);
            }
            @screen m {
                transform: none;
            }
        }
    }

    .after-image {
        margin-top: -14%;
        transform: translateX(16px);

        @screen sp {
            transform: translateX(32px);
        }
        @screen m {
            transform: none;
        }
    }

    .mapbox-wrapper {
        height: 260px;
        background: rgba(0, 0, 0, 0.1);

        @include from(m) {
            height: 377px;
        }
        @include from(l) {
            height: 650px;
        }

        canvas {
            outline: none;
        }
    }

    .mapboxgl-popup {
        .mapboxgl-popup-close-button {
            font-size: 22px;
            right: 5px;
            top: 5px;
        }

        .mapboxgl-popup-content {
            padding: 20px;
        }
    }

    .illustration-map {
        &__svg {
            padding-bottom: calc((1012/789) * 100%);
        }

        #bygg_1_hover,
        #bygg_2_hover,
        #bygg_3_hover,
        #bygg_4_hover,
        #bygg_5_hover,
        #bygg_6_hover,
        #bygg_7_hover,
        #bygg_8_hover,
        #big_field_hover,
        #medium_field_hover,
        #runway_hover {
            opacity: 0;
            transition: opacity 0.1s ease;

            &.active {
                opacity: 1;
            }
        }

        #big_field,
        #big_field_hover,
        #medium_field,
        #medium_field_hover {
            line {
                pointer-events: none;
            }
        }

        #fly_non-clickable {
            pointer-events: none;
        }
    }

    .illustration-map-list-link {
        &.active {
            @apply bg-yellow-hover;
        }
    }

    .illustration-map-modal-close {
        right: 0;
        top: -42px;

        @screen m {
            width: 48px;
            height: 48px;
            right: 24px;
            top: 24px;
        }

        .icon {
            @screen m {
                left: 12px;
                top: 12px;
            }

            span {
                width: 14px;
                height: 2px;
                left: 5px;
                top: 12px;

                @screen m {
                    width: 20px;
                    left: 2px;
                }
            }

            span:first-child {
                transform: rotate(-45deg);
            }

            span:last-child {
                transform: rotate(45deg);
            }
        }
    }

    .illustration-map-modal {
        &:target {
            display: flex;
        }
    }

    .menu-link {
        &__arrow {
            left: -30px;
            top: 0;
            opacity: 0;
            transition-property: opacity, left;
            transition-duration: 0.4s;
            transition-timing-function: ease, $easeOutQuint;
        }

        &:hover {
            .menu-link__arrow {
                opacity: 1;
                left: -25px;
            }
        }

        &__selected {
            .menu-link__arrow {
                left: calc(50% - 12px) !important;
                top: -20px !important;
                opacity: 1 !important;
                transform: rotate(90deg);
            }
        }
    }

    .menu-toggle {
        top: 12px;

        @screen m {
            display: none !important;
        }

        .icon {
            span {
                width: 14px;
                height: 2px;
                left: 5px;
            }

            span:first-child {
                top: 8px;
            }

            span:last-child {
                top: 14px;
            }
        }
    }

    .line-link {
        background-image: linear-gradient(to right, #303030, #303030);
        background-size: 0 2px;
        background-position: 0 100%;
        background-repeat: no-repeat;
        transition: background-size 500ms;
        @apply ease-out-quint;

        &--selected {
            background-size: 100% 0.1em;
        }
    }

    .using-mouse {
        .line-link:hover,
        a:hover .line-link {
            background-size: 100% 0.1em;
        }
    }

    .list-item-line-hover {
        &:before,
        &:after {
            content: '';
            display: block;
            width: 0%;
            height: 2px;
            background: #F7CC53;
            position: absolute;
            left: 0;
            z-index: 1;
            transition: width 0.7s;
            @apply ease-out-quint;
        }
        
        &:before {
            top: -2px;
        }
        
        &:after {
            bottom: -2px;
        }
        
        &:hover {
            &:before,
            &:after {
                width: 100%;
            }
        }
    }
}
