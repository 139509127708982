@layer utilities {

    .fullheight {
        height: 100vh;
        @supports (--custom:property) {
            height: calc(var(--vh, 1vh) * 100);
        }
    }

    .object-cover {
        object-fit: cover;
        width: 100%;
        height: 100%;

        html.no-objectfit & {
            object-fit: none !important;
            width: auto;
            height: auto;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: none;
            max-height: none;
            min-width: 100%;
            min-height: 100%;
        }
    }

    .object-contain {
        object-fit: contain;
        width: 100%;
        height: 100%;

        html.no-objectfit & {
            object-fit: none !important;
            width: auto;
            height: auto;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
        }
    }

    .placed-wrap {
        position: relative;
        height: 0;
    }

    .placed-image {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    .ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
    }

    .hit-expand-10 {
        &::before {
            content: "";
            display: block;
            position: absolute;
            width: calc(100% + 20px);
            height: calc(100% + 20px);
            left: -10px;
            top: -10px;
        }
    }
    

}
