/*

    @font-face declarations

    Paths to url() should be relative to the SASS entrypoint (base.scss), i.e. "../fonts/fontname.woff2"

 */

@layer base {
    @font-face {
        font-family: 'Aeonik';
        font-weight: normal;
        font-style: normal;
        src: url('../fonts/Aeonik-Regular.woff2') format('woff2'), url('../fonts/Aeonik-Regular.woff') format('woff');
    }
    @font-face {
        font-family: 'Aeonik';
        font-weight: normal;
        font-style: italic;
        src: url('../fonts/Aeonik-RegularItalic.woff2') format('woff2'), url('../fonts/Aeonik-RegularItalic.woff') format('woff');
    }
    @font-face {
        font-family: 'Aeonik';
        font-weight: 300;
        font-style: normal;
        src: url('../fonts/Aeonik-Light.woff2') format('woff2'), url('../fonts/Aeonik-Light.woff') format('woff');
    }
    @font-face {
        font-family: 'Aeonik';
        font-weight: 300;
        font-style: italic;
        src: url('../fonts/Aeonik-LightItalic.woff2') format('woff2'), url('../fonts/Aeonik-LightItalic.woff') format('woff');
    }
    @font-face {
        font-family: 'Aeonik';
        font-weight: 500;
        font-style: normal;
        src: url('../fonts/Aeonik-Medium.woff2') format('woff2'), url('../fonts/Aeonik-Medium.woff') format('woff');
    }
    @font-face {
        font-family: 'Aeonik';
        font-weight: 500;
        font-style: italic;
        src: url('../fonts/Aeonik-MediumItalic.woff2') format('woff2'), url('../fonts/Aeonik-MediumItalic.woff') format('woff');
    }
    @font-face {
        font-family: 'Aeonik';
        font-weight: 700;
        font-style: normal;
        src: url('../fonts/Aeonik-Bold.woff2') format('woff2'), url('../fonts/Aeonik-Bold.woff') format('woff');
    }
    @font-face {
        font-family: 'Aeonik';
        font-weight: 700;
        font-style: italic;
        src: url('../fonts/Aeonik-BoldItalic.woff2') format('woff2'), url('../fonts/Aeonik-BoldItalic.woff') format('woff');
    }
}
