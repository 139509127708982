.wysiwyg {
    p, ul, ol {
        margin-bottom: 16px;

        @screen l {
            margin-bottom: 24px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    em, i {
        font-style: italic;
    }

    strong, b {
        @apply bg-yellow pb-4 px-5 rounded-2px;
        font-weight: inherit;
    }
    
    &--animated-strong {
        strong, b {
            @apply ease-out-quart duration-900 delay-200;
            display: inline-block;
            position: relative;
            line-height: 1.1em;
            background-color: transparent;
            background-image: linear-gradient(to right, #F7CC53, #F7CC53);
            background-size: 0 100%;
            background-position: 0 100%;
            background-repeat: no-repeat;
            transition-property: background-size;
            
            &.is-visible {
                background-size: 100% 100%;
            }
        }
    }

    a {
        @apply border-yellow border-b-2;
    }

    h2 {
        /* Stiles med replace i twig */
    }

    h3 {
        /* Stiles med replace i twig */
    }

    ol {
        counter-reset: orderedlist;
    }
    
    li {
        position: relative;
        padding-left: 50px;
        margin-bottom: 24px;
        
        @screen l {
            margin-bottom: 34px;
            padding-left: 56px;
        }
    }
    
    ol li::before,
    ul li::before {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 28px;
        height: 28px;
        border: 2px solid #D5D1CB;
        
        @screen l {
            width: 32px;
            height: 32px;
        }
    }

    ol li::before {
        counter-increment: orderedlist;
        content: counter(orderedlist);
        text-align: center;
        line-height: 1.8;
        padding-left: 1px;
        
        @screen l {
            line-height: 1.5;
            padding-left: 2px;
        }
    }

    ul li::before {
        content: '';
        background-image: url("data:image/svg+xml, %3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.8653 12.1857L11.0253 8.3458L12.0872 7.28396L17.6558 12.8525L12.0503 18.458L10.9884 17.3962L14.816 13.5686L6.80272 13.6057L6.80272 12.0005L14.8653 12.1857Z' fill='%23303030'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
    }
}
