.page-header {
    &__bar {
        height: 72px;
        transition: transform 0.6s $easeOutQuint;

        @screen m {
            height: 90px;
        }
        @screen l {
            height: 120px;
        }
        
        &.is-hidden {
            transform: translateY(-72px);

            @screen m {
                transform: translateY(-90px);
            }
            @screen l {
                transform: translateY(-120px);
            }
        }        
    }

    &__logo {
        width: 100px;
        height: 40px;
        top: 17px;

        @screen m {
            top: 25px;
        }

        @screen l {
            width: 135px;
            height: 55px;
            top: 32px;
        }
    }
    
    &__logo-large {
        width: 100%;
        height: 0;
        padding-bottom: calc((187/1436) * 100%);
    }
    
    &__menu {
        @apply transition-transform duration-600 ease-out-quint;
        
        &:target {
            height: 100% !important;
        }
        
        &.is-hidden {
            @screen m {
                transform: translateY(-90px);
            }
            @screen l {
                transform: translateY(-120px);
            }
        }       
    }

    &__menu-close {
        right: 0;
        top: 12px;

        .icon {
            span {
                width: 14px;
                height: 2px;
                left: 5px;
                top: 12px;
            }

            span:first-child {
                transform: rotate(-45deg);
            }

            span:last-child {
                transform: rotate(45deg);
            }
        }
    }
}
